/*
 * @Author: wjc
 * @Date: 2023-11-27 15:35:24
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-11-25 14:11:21
 * @Description:
 */
import { Enterprise, type IEnterprise } from '@/models/enterprise'
import { getEnterpriseUser, postEnterpriseUser, putEnterpriseUser } from '@/api/user/user'
import { setEnterpriseUserIdSave, getEnterpriseUserIdSave, getToken } from '@/utils/auth'
import { navList } from '~/constants/nav'
import type { INav } from '~/models/nav'

export interface UserState {
  userInfo: Enterprise
  navMenu: INav[]
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => {
    return {
      userInfo: new Enterprise({}),
      navMenu: [],
    }
  },
  getters: {
    getUser(): Enterprise {
      if (process.browser) {
        const token = getToken()
        const userInfo = localStorage.getItem('userInfo')
        if (userInfo && token) {
          return JSON.parse(userInfo)
        }
        return this.userInfo
      } else {
        return this.userInfo
      }
    },
    getEnterpriseUserId(): string {
      return this.userInfo.id || getEnterpriseUserIdSave() || ''
    },
  },
  actions: {
    clearUserInfo() {
      this.userInfo = new Enterprise({})
    },
    getUserEnterpriseInfo(): Promise<Enterprise> {
      return new Promise((resolve, reject) => {
        getEnterpriseUser()
          .then((res) => {
            const data = res.data || {}
            const token = getToken()
            this.userInfo = new Enterprise(data)
            if (process.browser) {
              localStorage.setItem('userInfo', JSON.stringify(data))
            }
            // 已经登录但还未填写企业基本情况时，只显示企业基本情况路由
            if (!data.id && token) {
              this.navMenu = navList.filter((item) => item.path === '/admin/user')
            } else {
              this.navMenu = navList
            }
            setEnterpriseUserIdSave(this.userInfo.id || '')
            resolve(this.userInfo)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    postUserEnterpriseInfo(info: IEnterprise): Promise<number> {
      return new Promise((resolve, reject) => {
        postEnterpriseUser(info)
          .then((res) => {
            this.userInfo = this.userInfo
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    putUserEnterpriseInfo(info: IEnterprise): Promise<number> {
      return new Promise((resolve, reject) => {
        putEnterpriseUser(info.id || '', info)
          .then((res) => {
            this.userInfo = this.userInfo
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
})
