import unhead_KgADcZ0jPj from "/var/jenkins_home/workspace/hn-wy-website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/jenkins_home/workspace/hn-wy-website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/var/jenkins_home/workspace/hn-wy-website/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/jenkins_home/workspace/hn-wy-website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/jenkins_home/workspace/hn-wy-website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/var/jenkins_home/workspace/hn-wy-website/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/var/jenkins_home/workspace/hn-wy-website/.nuxt/element-plus-injection.plugin.mjs";
import unocss_MzCDxu9LMj from "/var/jenkins_home/workspace/hn-wy-website/.nuxt/unocss.mjs";
import chunk_reload_client_UciE0i6zes from "/var/jenkins_home/workspace/hn-wy-website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/jenkins_home/workspace/hn-wy-website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  unocss_MzCDxu9LMj,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ
]