/*
 * @Author: wjc
 * @Date: 2023-11-17 11:46:48
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-07 17:03:21
 * @Description:
 */
import { apiBase } from '~/constants'
import Request from './request'
import { checkStatus } from './checkStatus'
import whiteList from './whiteList'

const http = new Request({
  baseURL: `${apiBase}`,
  interceptor: {
    // 请求前钩子函数
    onRequest({ request, options }) {
      const isHas = whiteList.find((item) => (request as string).includes(item))
      if (isHas) {
        const h = options.headers
        options.headers = {
          ...h,
          Authorization: '',
        }
      }
    },
    // 响应错误拦截
    onResponseError({ response }) {
      if (response?.status && response?.status !== 401) {
        checkStatus(response?.status, response._data.message || '服务不可用，请稍候再试')
      }
    },
  },
})

export { http }
