/*
 * @Author: wjc
 * @Date: 2023-11-27 15:35:24
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-07 11:24:17
 * @Description: 
 */
import type { INav } from '~/models/nav'

export const navList: INav[] = [
  {
    title: '首页',
    path: '/admin',
    icon: 'i-custom-nav-home',
    children: [],
  },
  {
    title: '企业基本情况',
    path: '/admin/user',
    icon: 'i-custom-nav-enterprise',
    children: [],
  },
  {
    title: '在管项目管理',
    path: '/admin/project',
    icon: 'i-custom-nav-project',
    children: [],
  },
  {
    title: '项目经理管理',
    path: '/admin/manager',
    icon: 'i-custom-nav-manager',
    children: [],
  },
  // {
  //   title: '中级以上员工管理',
  //   path: '/admin/employee',
  //   icon: 'i-custom-nav-employee',
  //   children: [],
  // },
  {
    title: '企业良好行为',
    path: '/admin/action',
    icon: 'i-custom-nav-action',
    children: [],
  },
  {
    title: '账号编辑',
    path: '/admin/account',
    icon: 'i-custom-nav-account',
    children: [],
  },
]
