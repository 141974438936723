export default {
  "admin-components-aside": () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/admin/components/aside.vue").then(m => m.default || m),
  "admin-components-header": () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/admin/components/header.vue").then(m => m.default || m),
  "admin-components-logo": () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/admin/components/logo.vue").then(m => m.default || m),
  "admin-components-menu-vertical": () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/admin/components/menuVertical.vue").then(m => m.default || m),
  admin: () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/admin/index.vue").then(m => m.default || m),
  "default-components-footer": () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/default/components/footer.vue").then(m => m.default || m),
  "default-components-headers": () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/default/components/headers.vue").then(m => m.default || m),
  "default-components-menu-screen": () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/default/components/menu-screen/index.vue").then(m => m.default || m),
  "default-components-menu-screen-menu-group": () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/default/components/menu-screen/menu-group.vue").then(m => m.default || m),
  "default-components-menu": () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/default/components/menu.vue").then(m => m.default || m),
  default: () => import("/var/jenkins_home/workspace/hn-wy-website/layouts/default/index.vue").then(m => m.default || m)
}