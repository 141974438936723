/*
 * @Author: ChenYaJin
 * @Date: 2023-07-04 11:07:44
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-11-27 14:52:11
 * @Description: 登录相关状态
 */
import {
  setToken as setTokenCache,
  getToken as getTokenCache,
  setRefreshToken,
  getRefreshToken,
  setUuIdSave,
} from '~/utils/auth'
import { postLogin, postRegister, getAccountInfo, postAccountInfo } from '~/api/user/login'
import type { ILogin, IAccount, ILoginToken } from '~/models/login'
import { useUserStore } from './user'

export interface LoginState {
  token: string
  refreshToken: string
  isAccessTokenNoWorking: boolean // accessToken是否过期
  timer: unknown
  isShowAccount: boolean // 是否显示账号信息
  accountInfo: IAccount
}
export const useLoginStore = defineStore({
  id: 'login',
  state: (): LoginState => {
    return {
      token: '',
      refreshToken: '',
      isAccessTokenNoWorking: false,
      timer: '',
      isShowAccount: false,
      accountInfo: {},
    }
  },
  getters: {
    getToken(): string {
      return this.token || getTokenCache() || ''
    },
    getRefreshToken(): string {
      return this.refreshToken || getRefreshToken() || ''
    },
    isLogin(): boolean {
      return !!this.token || !!getTokenCache()
    },
    isAccessTokenExpire(): boolean {
      return this.isAccessTokenNoWorking
    },
  },
  actions: {
    setAccountClose(flag: boolean) {
      this.isShowAccount = flag
    },
    setToken(info: string) {
      this.token = info ?? ''
      setTokenCache(info)
    },
    setRefreshToken(info: string) {
      this.refreshToken = info ?? ''
      setRefreshToken(info)
    },
    // 登录
    login(loginForm: ILogin) {
      return new Promise((resolve, reject) => {
        postLogin(loginForm)
          .then((res) => {
            this.isAccessTokenNoWorking = false
            const data = res.data
            this.setToken(data.accessToken)
            this.setRefreshToken(data.refreshToken)
            setUuIdSave(data.uid)
            resolve(res.data)
          })
          .catch((error: Error) => {
            reject(error)
          })
      })
    },
    // 注册
    register(registerForm: IAccount) {
      return new Promise((resolve, reject) => {
        postRegister(registerForm)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error: Error) => {
            reject(error)
          })
      })
    },
    updateToken(data: ILoginToken) {
      this.setToken(data.accessToken)
    },
    // 退出登录
    logout() {
      return new Promise((resolve) => {
        const userStore = useUserStore()
        // 数据清理
        if (process.browser) {
          localStorage.clear()
        }
        userStore.$reset()
        this.$reset()
        resolve(1)
      })
    },
    // 获取用户账号信息
    getUserAccountInfo(uuid: string): Promise<IAccount> {
      return new Promise((resolve, reject) => {
        if (this.accountInfo.name) {
          resolve(this.accountInfo)
          return
        }
        getAccountInfo(uuid)
          .then((res) => {
            const data = res.data
            this.accountInfo = data
            resolve(res.data)
          })
          .catch((error: Error) => {
            reject(error)
          })
      })
    },
    updateAccountInfo(data: IAccount) {
      return new Promise((resolve, reject) => {
        postAccountInfo(data)
          .then((res) => {
            this.accountInfo = {}
            resolve(res.data)
          })
          .catch((error: Error) => {
            reject(error)
          })
      })
    },
  },
})
