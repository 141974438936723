import type { Config } from '~/types/config'

export const appName = '海南省物业管理行业协会'
export const appDescription = '海南省物业管理行业协会'
export const apiBase = import.meta.env.VITE_API_URL

const config: Config = {
  websiteTitle: appName,
  footerWrite: {
    url: import.meta.env.VITE_INDEX_URL,
    adminUrl: import.meta.env.VITE_ADMIN_URL,
    holdUnit: '海南省住建厅',
    address: '海南省海口市海府路59号',
    organizer: '海南省物业协会',
    supportUnit: '支持单位',
    contactPhone: '0898-65342877',
    identificationCode: '0000000000',
    icpCode: '琼ICP备00000000 号 ',
    publicNetworkCode: '46010802000270号',
    postalCode: '00000000',
    advise: '建议您使用 Chrome、Firefox或360等浏览器浏览本网站',
  },
  defaultSettings: {
    /**
     * @type String
     * @description Application's default title
     */
    title: '智慧城',

    /**
     * @type String
     * @description accessToken
     */
    cookieTokenName: 'wctm__',

    /**
     * @type String
     * @description refreshToken
     */
    cookieRefreshTokenName: 'wcRhtm__',

    /**
     * @type String
     * @description 当前登录企业 ID
     */
    enterpriseUserIdName: 'wcEnI__',

    /**
     * @type String
     * @description 当前登录uuid
     */
    uuidName: 'wcuutm__',

    /**
     * @type {boolean} true | false
     * @description Whether need tagsView
     */
    tagsView: true,

    /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
    fixedHeader: true,

    /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
    sidebarLogo: true,

    /**
     * @type {string | array} 'production' | ['production', 'development']
     * @description Need show err logs component.
     * The default is only used in the production env
     * If you want to also use it in dev, you can pass ['production', 'development']
     */
    errorLog: ['production', 'development'],
    fileUrl: '/yyds/file/download/',
  },
}

export const typeList = [
  {
    text: '协会简介',
    link: '/content/912305321901290102',
    icon: 'xhjj',
  },
  {
    text: '会员查询',
    link: '/content/912305321901290801',
    icon: 'hycx',
  },
  {
    text: '协会章程',
    link: '/content/912305321901290103',
    icon: 'xgzc',
  },
  {
    text: '协会领导',
    link: '/about/associationLeadership',
    icon: 'xhld',
  },
  {
    text: '主要职责',
    link: 'about/primaryResponsibilities',
    icon: 'zyzz',
  },
  {
    text: '联系我们',
    link: '/about/contactUs',
    icon: 'lxwom',
  },
]

export default config
