/*
 * @Author: wjc
 * @Date: 2023-12-07 11:46:49
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-07 16:12:23
 * @Description:
 */
const whiteList: string[] = [
  '/auth/register/root',
  '/ent/auth/register',
  '/open/enterprise',
  '/info/open',
  '/infoClass/open',
  '/file/images',
  '/activity/participant/export',
  '/activity/standard/details/export',
  '/singlePage/introductionOfTheAssociation/open',
  '/singlePage/enterpriseShowcase/open',
  '/singlePage/standardPage/open',
]

export default whiteList
