import { default as _91_46_46_46all_93xI5pIRLoeQMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/[...all].vue?macro=true";
import { default as _91id_93RwWQWXnhfHMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/about/[id].vue?macro=true";
import { default as indexepjpr4k2HPMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/about/index.vue?macro=true";
import { default as indexHD9n0GMLiAMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/account/index.vue?macro=true";
import { default as listgFkWxjFYXTMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/action/components/list.vue?macro=true";
import { default as searchYFvh0saYYkMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/action/components/search.vue?macro=true";
import { default as indexWCROf8vUa9Meta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/action/index.vue?macro=true";
import { default as serializevw0CkgBf0eMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/common/serialize.ts?macro=true";
import { default as table_settingSTNJoh6Bd4Meta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/common/table_setting.ts?macro=true";
import { default as add_45blockidNBjTbtsxMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/components/add-block.vue?macro=true";
import { default as indexMnn8Z1notUMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/components/audit-status/index.vue?macro=true";
import { default as indexYGuiMVgt7bMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/components/table-setting/index.vue?macro=true";
import { default as listJ2IKAS9knmMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/employee/components/list.vue?macro=true";
import { default as search6TMHyoWSc2Meta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/employee/components/search.vue?macro=true";
import { default as employeeCmcmxq5t1EMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/employee/employee.vue?macro=true";
import { default as indexXuwAvNpYhaMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/employee/index.vue?macro=true";
import { default as activitymBjybp6QEqMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/components/activity.vue?macro=true";
import { default as handleFrmJpnR8moMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/components/handle.vue?macro=true";
import { default as infoEQckWTIQLEMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/components/info.vue?macro=true";
import { default as projectJR6yK2u7WXMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/components/project.vue?macro=true";
import { default as indexm0Se5THyoUMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/index.vue?macro=true";
import { default as _32activity_45joinFWQqxWoS4JMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/modal/ activity-join.vue?macro=true";
import { default as activity_45detailvXlT6mLfwSMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/modal/activity-detail.vue?macro=true";
import { default as activity_45listfpe1073D7JMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/modal/activity-list.vue?macro=true";
import { default as indexBG4O9RKbGEMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/manager/index.vue?macro=true";
import { default as activity_45score_45detail6NSvdahA1GMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/modal/activity-score-detail.vue?macro=true";
import { default as activity_45score_45listXsXr7uAVIKMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/modal/activity-score-list.vue?macro=true";
import { default as business_45modalnBQ2iNSJgMMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/modal/business-modal.vue?macro=true";
import { default as list8DDvHy0x5AMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/project/components/list.vue?macro=true";
import { default as search1S6atCaDV6Meta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/project/components/search.vue?macro=true";
import { default as indexDu6mz3nOU2Meta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/project/index.vue?macro=true";
import { default as merge_45table_45containerYwctsh6ARzMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/user/components/merge-table-container.vue?macro=true";
import { default as index4AhCpopK60Meta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/user/components/table-merge/index.vue?macro=true";
import { default as index2MKxcGilGJMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/user/index.vue?macro=true";
import { default as normalizeu3hv3neWICMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/user/normalize.ts?macro=true";
import { default as settingcBLK8oi05NMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/admin/user/setting.ts?macro=true";
import { default as _91id_93IZj2EBxUKRMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/content/[id].vue?macro=true";
import { default as _91id_93i0bq2AV1PvMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/content/post/[id].vue?macro=true";
import { default as indexb5zrcyAhgfMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/index/index.vue?macro=true";
import { default as index7t3bEdbE3nMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/login/index.vue?macro=true";
import { default as indexmFMUBHKjPJMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/register/index.vue?macro=true";
import { default as companyk2WbxvThjtMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/site/components/company.vue?macro=true";
import { default as party_45linecBcNbi7XnOMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/site/components/party-line.vue?macro=true";
import { default as rulesO02y1HcS58Meta } from "/var/jenkins_home/workspace/hn-wy-website/pages/site/components/rules.vue?macro=true";
import { default as services_45listPA5fzXRSXYMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/site/components/services-list.vue?macro=true";
import { default as index1czAqAScFRMeta } from "/var/jenkins_home/workspace/hn-wy-website/pages/site/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93xI5pIRLoeQMeta?.name ?? "all",
    path: _91_46_46_46all_93xI5pIRLoeQMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93xI5pIRLoeQMeta || {},
    alias: _91_46_46_46all_93xI5pIRLoeQMeta?.alias || [],
    redirect: _91_46_46_46all_93xI5pIRLoeQMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91id_93RwWQWXnhfHMeta?.name ?? "about-id",
    path: _91id_93RwWQWXnhfHMeta?.path ?? "/about/:id()",
    meta: _91id_93RwWQWXnhfHMeta || {},
    alias: _91id_93RwWQWXnhfHMeta?.alias || [],
    redirect: _91id_93RwWQWXnhfHMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/about/[id].vue").then(m => m.default || m)
  },
  {
    name: indexepjpr4k2HPMeta?.name ?? "about",
    path: indexepjpr4k2HPMeta?.path ?? "/about",
    meta: indexepjpr4k2HPMeta || {},
    alias: indexepjpr4k2HPMeta?.alias || [],
    redirect: indexepjpr4k2HPMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexHD9n0GMLiAMeta?.name ?? "admin-account",
    path: indexHD9n0GMLiAMeta?.path ?? "/admin/account",
    meta: indexHD9n0GMLiAMeta || {},
    alias: indexHD9n0GMLiAMeta?.alias || [],
    redirect: indexHD9n0GMLiAMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/account/index.vue").then(m => m.default || m)
  },
  {
    name: listgFkWxjFYXTMeta?.name ?? "admin-action-components-list",
    path: listgFkWxjFYXTMeta?.path ?? "/admin/action/components/list",
    meta: listgFkWxjFYXTMeta || {},
    alias: listgFkWxjFYXTMeta?.alias || [],
    redirect: listgFkWxjFYXTMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/action/components/list.vue").then(m => m.default || m)
  },
  {
    name: searchYFvh0saYYkMeta?.name ?? "admin-action-components-search",
    path: searchYFvh0saYYkMeta?.path ?? "/admin/action/components/search",
    meta: searchYFvh0saYYkMeta || {},
    alias: searchYFvh0saYYkMeta?.alias || [],
    redirect: searchYFvh0saYYkMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/action/components/search.vue").then(m => m.default || m)
  },
  {
    name: indexWCROf8vUa9Meta?.name ?? "admin-action",
    path: indexWCROf8vUa9Meta?.path ?? "/admin/action",
    meta: indexWCROf8vUa9Meta || {},
    alias: indexWCROf8vUa9Meta?.alias || [],
    redirect: indexWCROf8vUa9Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/action/index.vue").then(m => m.default || m)
  },
  {
    name: serializevw0CkgBf0eMeta?.name ?? "admin-common-serialize",
    path: serializevw0CkgBf0eMeta?.path ?? "/admin/common/serialize",
    meta: serializevw0CkgBf0eMeta || {},
    alias: serializevw0CkgBf0eMeta?.alias || [],
    redirect: serializevw0CkgBf0eMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/common/serialize.ts").then(m => m.default || m)
  },
  {
    name: table_settingSTNJoh6Bd4Meta?.name ?? "admin-common-table_setting",
    path: table_settingSTNJoh6Bd4Meta?.path ?? "/admin/common/table_setting",
    meta: table_settingSTNJoh6Bd4Meta || {},
    alias: table_settingSTNJoh6Bd4Meta?.alias || [],
    redirect: table_settingSTNJoh6Bd4Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/common/table_setting.ts").then(m => m.default || m)
  },
  {
    name: add_45blockidNBjTbtsxMeta?.name ?? "admin-components-add-block",
    path: add_45blockidNBjTbtsxMeta?.path ?? "/admin/components/add-block",
    meta: add_45blockidNBjTbtsxMeta || {},
    alias: add_45blockidNBjTbtsxMeta?.alias || [],
    redirect: add_45blockidNBjTbtsxMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/components/add-block.vue").then(m => m.default || m)
  },
  {
    name: indexMnn8Z1notUMeta?.name ?? "admin-components-audit-status",
    path: indexMnn8Z1notUMeta?.path ?? "/admin/components/audit-status",
    meta: indexMnn8Z1notUMeta || {},
    alias: indexMnn8Z1notUMeta?.alias || [],
    redirect: indexMnn8Z1notUMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/components/audit-status/index.vue").then(m => m.default || m)
  },
  {
    name: indexYGuiMVgt7bMeta?.name ?? "admin-components-table-setting",
    path: indexYGuiMVgt7bMeta?.path ?? "/admin/components/table-setting",
    meta: indexYGuiMVgt7bMeta || {},
    alias: indexYGuiMVgt7bMeta?.alias || [],
    redirect: indexYGuiMVgt7bMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/components/table-setting/index.vue").then(m => m.default || m)
  },
  {
    name: listJ2IKAS9knmMeta?.name ?? "admin-employee-components-list",
    path: listJ2IKAS9knmMeta?.path ?? "/admin/employee/components/list",
    meta: listJ2IKAS9knmMeta || {},
    alias: listJ2IKAS9knmMeta?.alias || [],
    redirect: listJ2IKAS9knmMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/employee/components/list.vue").then(m => m.default || m)
  },
  {
    name: search6TMHyoWSc2Meta?.name ?? "admin-employee-components-search",
    path: search6TMHyoWSc2Meta?.path ?? "/admin/employee/components/search",
    meta: search6TMHyoWSc2Meta || {},
    alias: search6TMHyoWSc2Meta?.alias || [],
    redirect: search6TMHyoWSc2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/employee/components/search.vue").then(m => m.default || m)
  },
  {
    name: employeeCmcmxq5t1EMeta?.name ?? "admin-employee-employee",
    path: employeeCmcmxq5t1EMeta?.path ?? "/admin/employee/employee",
    meta: employeeCmcmxq5t1EMeta || {},
    alias: employeeCmcmxq5t1EMeta?.alias || [],
    redirect: employeeCmcmxq5t1EMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/employee/employee.vue").then(m => m.default || m)
  },
  {
    name: indexXuwAvNpYhaMeta?.name ?? "admin-employee",
    path: indexXuwAvNpYhaMeta?.path ?? "/admin/employee",
    meta: indexXuwAvNpYhaMeta || {},
    alias: indexXuwAvNpYhaMeta?.alias || [],
    redirect: indexXuwAvNpYhaMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/employee/index.vue").then(m => m.default || m)
  },
  {
    name: activitymBjybp6QEqMeta?.name ?? "admin-index-components-activity",
    path: activitymBjybp6QEqMeta?.path ?? "/admin/components/activity",
    meta: activitymBjybp6QEqMeta || {},
    alias: activitymBjybp6QEqMeta?.alias || [],
    redirect: activitymBjybp6QEqMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/components/activity.vue").then(m => m.default || m)
  },
  {
    name: handleFrmJpnR8moMeta?.name ?? "admin-index-components-handle",
    path: handleFrmJpnR8moMeta?.path ?? "/admin/components/handle",
    meta: handleFrmJpnR8moMeta || {},
    alias: handleFrmJpnR8moMeta?.alias || [],
    redirect: handleFrmJpnR8moMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/components/handle.vue").then(m => m.default || m)
  },
  {
    name: infoEQckWTIQLEMeta?.name ?? "admin-index-components-info",
    path: infoEQckWTIQLEMeta?.path ?? "/admin/components/info",
    meta: infoEQckWTIQLEMeta || {},
    alias: infoEQckWTIQLEMeta?.alias || [],
    redirect: infoEQckWTIQLEMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/components/info.vue").then(m => m.default || m)
  },
  {
    name: projectJR6yK2u7WXMeta?.name ?? "admin-index-components-project",
    path: projectJR6yK2u7WXMeta?.path ?? "/admin/components/project",
    meta: projectJR6yK2u7WXMeta || {},
    alias: projectJR6yK2u7WXMeta?.alias || [],
    redirect: projectJR6yK2u7WXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/components/project.vue").then(m => m.default || m)
  },
  {
    name: indexm0Se5THyoUMeta?.name ?? "admin-index",
    path: indexm0Se5THyoUMeta?.path ?? "/admin",
    meta: indexm0Se5THyoUMeta || {},
    alias: indexm0Se5THyoUMeta?.alias || [],
    redirect: indexm0Se5THyoUMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/index.vue").then(m => m.default || m)
  },
  {
    name: _32activity_45joinFWQqxWoS4JMeta?.name ?? "admin-index-modal- activity-join",
    path: _32activity_45joinFWQqxWoS4JMeta?.path ?? "/admin/modal/%20activity-join",
    meta: _32activity_45joinFWQqxWoS4JMeta || {},
    alias: _32activity_45joinFWQqxWoS4JMeta?.alias || [],
    redirect: _32activity_45joinFWQqxWoS4JMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/modal/ activity-join.vue").then(m => m.default || m)
  },
  {
    name: activity_45detailvXlT6mLfwSMeta?.name ?? "admin-index-modal-activity-detail",
    path: activity_45detailvXlT6mLfwSMeta?.path ?? "/admin/modal/activity-detail",
    meta: activity_45detailvXlT6mLfwSMeta || {},
    alias: activity_45detailvXlT6mLfwSMeta?.alias || [],
    redirect: activity_45detailvXlT6mLfwSMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/modal/activity-detail.vue").then(m => m.default || m)
  },
  {
    name: activity_45listfpe1073D7JMeta?.name ?? "admin-index-modal-activity-list",
    path: activity_45listfpe1073D7JMeta?.path ?? "/admin/modal/activity-list",
    meta: activity_45listfpe1073D7JMeta || {},
    alias: activity_45listfpe1073D7JMeta?.alias || [],
    redirect: activity_45listfpe1073D7JMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/index/modal/activity-list.vue").then(m => m.default || m)
  },
  {
    name: indexBG4O9RKbGEMeta?.name ?? "admin-manager",
    path: indexBG4O9RKbGEMeta?.path ?? "/admin/manager",
    meta: indexBG4O9RKbGEMeta || {},
    alias: indexBG4O9RKbGEMeta?.alias || [],
    redirect: indexBG4O9RKbGEMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/manager/index.vue").then(m => m.default || m)
  },
  {
    name: activity_45score_45detail6NSvdahA1GMeta?.name ?? "admin-modal-activity-score-detail",
    path: activity_45score_45detail6NSvdahA1GMeta?.path ?? "/admin/modal/activity-score-detail",
    meta: activity_45score_45detail6NSvdahA1GMeta || {},
    alias: activity_45score_45detail6NSvdahA1GMeta?.alias || [],
    redirect: activity_45score_45detail6NSvdahA1GMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/modal/activity-score-detail.vue").then(m => m.default || m)
  },
  {
    name: activity_45score_45listXsXr7uAVIKMeta?.name ?? "admin-modal-activity-score-list",
    path: activity_45score_45listXsXr7uAVIKMeta?.path ?? "/admin/modal/activity-score-list",
    meta: activity_45score_45listXsXr7uAVIKMeta || {},
    alias: activity_45score_45listXsXr7uAVIKMeta?.alias || [],
    redirect: activity_45score_45listXsXr7uAVIKMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/modal/activity-score-list.vue").then(m => m.default || m)
  },
  {
    name: business_45modalnBQ2iNSJgMMeta?.name ?? "admin-modal-business-modal",
    path: business_45modalnBQ2iNSJgMMeta?.path ?? "/admin/modal/business-modal",
    meta: business_45modalnBQ2iNSJgMMeta || {},
    alias: business_45modalnBQ2iNSJgMMeta?.alias || [],
    redirect: business_45modalnBQ2iNSJgMMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/modal/business-modal.vue").then(m => m.default || m)
  },
  {
    name: list8DDvHy0x5AMeta?.name ?? "admin-project-components-list",
    path: list8DDvHy0x5AMeta?.path ?? "/admin/project/components/list",
    meta: list8DDvHy0x5AMeta || {},
    alias: list8DDvHy0x5AMeta?.alias || [],
    redirect: list8DDvHy0x5AMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/project/components/list.vue").then(m => m.default || m)
  },
  {
    name: search1S6atCaDV6Meta?.name ?? "admin-project-components-search",
    path: search1S6atCaDV6Meta?.path ?? "/admin/project/components/search",
    meta: search1S6atCaDV6Meta || {},
    alias: search1S6atCaDV6Meta?.alias || [],
    redirect: search1S6atCaDV6Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/project/components/search.vue").then(m => m.default || m)
  },
  {
    name: indexDu6mz3nOU2Meta?.name ?? "admin-project",
    path: indexDu6mz3nOU2Meta?.path ?? "/admin/project",
    meta: indexDu6mz3nOU2Meta || {},
    alias: indexDu6mz3nOU2Meta?.alias || [],
    redirect: indexDu6mz3nOU2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/project/index.vue").then(m => m.default || m)
  },
  {
    name: merge_45table_45containerYwctsh6ARzMeta?.name ?? "admin-user-components-merge-table-container",
    path: merge_45table_45containerYwctsh6ARzMeta?.path ?? "/admin/user/components/merge-table-container",
    meta: merge_45table_45containerYwctsh6ARzMeta || {},
    alias: merge_45table_45containerYwctsh6ARzMeta?.alias || [],
    redirect: merge_45table_45containerYwctsh6ARzMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/user/components/merge-table-container.vue").then(m => m.default || m)
  },
  {
    name: index4AhCpopK60Meta?.name ?? "admin-user-components-table-merge",
    path: index4AhCpopK60Meta?.path ?? "/admin/user/components/table-merge",
    meta: index4AhCpopK60Meta || {},
    alias: index4AhCpopK60Meta?.alias || [],
    redirect: index4AhCpopK60Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/user/components/table-merge/index.vue").then(m => m.default || m)
  },
  {
    name: index2MKxcGilGJMeta?.name ?? "admin-user",
    path: index2MKxcGilGJMeta?.path ?? "/admin/user",
    meta: index2MKxcGilGJMeta || {},
    alias: index2MKxcGilGJMeta?.alias || [],
    redirect: index2MKxcGilGJMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/user/index.vue").then(m => m.default || m)
  },
  {
    name: normalizeu3hv3neWICMeta?.name ?? "admin-user-normalize",
    path: normalizeu3hv3neWICMeta?.path ?? "/admin/user/normalize",
    meta: normalizeu3hv3neWICMeta || {},
    alias: normalizeu3hv3neWICMeta?.alias || [],
    redirect: normalizeu3hv3neWICMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/user/normalize.ts").then(m => m.default || m)
  },
  {
    name: settingcBLK8oi05NMeta?.name ?? "admin-user-setting",
    path: settingcBLK8oi05NMeta?.path ?? "/admin/user/setting",
    meta: settingcBLK8oi05NMeta || {},
    alias: settingcBLK8oi05NMeta?.alias || [],
    redirect: settingcBLK8oi05NMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/admin/user/setting.ts").then(m => m.default || m)
  },
  {
    name: _91id_93IZj2EBxUKRMeta?.name ?? "content-id",
    path: _91id_93IZj2EBxUKRMeta?.path ?? "/content/:id()",
    meta: _91id_93IZj2EBxUKRMeta || {},
    alias: _91id_93IZj2EBxUKRMeta?.alias || [],
    redirect: _91id_93IZj2EBxUKRMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/content/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93i0bq2AV1PvMeta?.name ?? "content-post-id",
    path: _91id_93i0bq2AV1PvMeta?.path ?? "/content/post/:id()",
    meta: _91id_93i0bq2AV1PvMeta || {},
    alias: _91id_93i0bq2AV1PvMeta?.alias || [],
    redirect: _91id_93i0bq2AV1PvMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/content/post/[id].vue").then(m => m.default || m)
  },
  {
    name: indexb5zrcyAhgfMeta?.name ?? "index",
    path: indexb5zrcyAhgfMeta?.path ?? "/",
    meta: indexb5zrcyAhgfMeta || {},
    alias: indexb5zrcyAhgfMeta?.alias || [],
    redirect: indexb5zrcyAhgfMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: index7t3bEdbE3nMeta?.name ?? "login",
    path: index7t3bEdbE3nMeta?.path ?? "/login",
    meta: index7t3bEdbE3nMeta || {},
    alias: index7t3bEdbE3nMeta?.alias || [],
    redirect: index7t3bEdbE3nMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFMUBHKjPJMeta?.name ?? "register",
    path: indexmFMUBHKjPJMeta?.path ?? "/register",
    meta: indexmFMUBHKjPJMeta || {},
    alias: indexmFMUBHKjPJMeta?.alias || [],
    redirect: indexmFMUBHKjPJMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: companyk2WbxvThjtMeta?.name ?? "site-components-company",
    path: companyk2WbxvThjtMeta?.path ?? "/site/components/company",
    meta: companyk2WbxvThjtMeta || {},
    alias: companyk2WbxvThjtMeta?.alias || [],
    redirect: companyk2WbxvThjtMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/site/components/company.vue").then(m => m.default || m)
  },
  {
    name: party_45linecBcNbi7XnOMeta?.name ?? "site-components-party-line",
    path: party_45linecBcNbi7XnOMeta?.path ?? "/site/components/party-line",
    meta: party_45linecBcNbi7XnOMeta || {},
    alias: party_45linecBcNbi7XnOMeta?.alias || [],
    redirect: party_45linecBcNbi7XnOMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/site/components/party-line.vue").then(m => m.default || m)
  },
  {
    name: rulesO02y1HcS58Meta?.name ?? "site-components-rules",
    path: rulesO02y1HcS58Meta?.path ?? "/site/components/rules",
    meta: rulesO02y1HcS58Meta || {},
    alias: rulesO02y1HcS58Meta?.alias || [],
    redirect: rulesO02y1HcS58Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/site/components/rules.vue").then(m => m.default || m)
  },
  {
    name: services_45listPA5fzXRSXYMeta?.name ?? "site-components-services-list",
    path: services_45listPA5fzXRSXYMeta?.path ?? "/site/components/services-list",
    meta: services_45listPA5fzXRSXYMeta || {},
    alias: services_45listPA5fzXRSXYMeta?.alias || [],
    redirect: services_45listPA5fzXRSXYMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/site/components/services-list.vue").then(m => m.default || m)
  },
  {
    name: index1czAqAScFRMeta?.name ?? "site",
    path: index1czAqAScFRMeta?.path ?? "/site",
    meta: index1czAqAScFRMeta || {},
    alias: index1czAqAScFRMeta?.alias || [],
    redirect: index1czAqAScFRMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/hn-wy-website/pages/site/index.vue").then(m => m.default || m)
  }
]