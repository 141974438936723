/*
 * @Author: wjc
 * @Date: 2023-05-25 16:51:28
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-11-27 15:09:52
 * @Description:
 */
import md5 from 'crypto-js/md5'
import type { ChunkList } from '~/models/enterprise'
import { useLoginStore } from '~/stores/modules/user/login'

/**
 * 打开新标签页
 * @param href 链接
 */
export function openNew(href: string) {
  let newWin = window.open('about:blank', '_blank')
  newWin!.location.href = href
  newWin = null
}

/**
 * @description MD5加密字符串
 * @param code 加密目标
 * @returns string
 */
export const encryptByMd5 = (code: string) => {
  return md5(code).toString().toUpperCase()
}

/**
 * @description 校验手机号码
 * @param string value 值
 */
export const testMobile = (value: string) => {
  return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value)
}

/**
 * @description 校验邮箱
 * @param string value 值
 */
export const checkEmail = (value: string) => {
  return /^[a-zA-Z0-9]+([a-zA-Z0-9-_.]*)@([a-zA-Z0-9]+[-|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/g.test(
    value
  )
}

/**
 * @description 校验固定电话
 * @param string value 值
 */
export const testPhone = (value: string) => {
  return /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(value)
}

/**
 * @description 校验身份证18位
 * @param string value 值
 */
export const testIdCard = (value: string) => {
  return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
    value
  )
}

/**
 * 利用Canvas生成设备指纹
 */
export function getCanvasFingerprint(): string {
  const canvas = document.createElement('canvas') as HTMLCanvasElement
  const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!
  const txt = 'BrowserLeaks,com <canvas> 1.0'

  ctx.textBaseline = 'top'
  ctx.font = "14px 'Arial'"
  ctx.textBaseline = 'alphabetic'
  ctx.fillStyle = '#f60'
  ctx.fillRect(125, 1, 62, 20)
  ctx.fillStyle = '#069'
  ctx.fillText(txt, 2, 15)
  ctx.fillStyle = 'rgba(102, 204, 0, 0.7)'
  ctx.fillText(txt, 4, 17)

  const canvasImageData = canvas.toDataURL()

  return encryptByMd5(canvasImageData)
}

/**
 * 复制字符串到剪贴板
 */
export async function copyToClipboard(text: string) {
  try {
    // 优先调用浏览器的复制方法
    return window.navigator.clipboard.writeText(text)
  } catch {
    const element = document.createElement('textarea')
    const previouslyFocusedElement = document.activeElement

    element.value = text

    // Prevent keyboard from showing on mobile
    element.setAttribute('readonly', '')

    element.style.contain = 'strict'
    element.style.position = 'absolute'
    element.style.left = '-9999px'
    element.style.fontSize = '12pt' // Prevent zooming on iOS

    const selection = document.getSelection()
    const originalRange = selection ? selection.rangeCount > 0 && selection.getRangeAt(0) : null

    document.body.appendChild(element)
    element.select()

    // Explicit selection workaround for iOS
    element.selectionStart = 0
    element.selectionEnd = text.length

    document.execCommand('copy')
    document.body.removeChild(element)

    if (originalRange) {
      selection!.removeAllRanges() // originalRange can't be truthy when selection is falsy
      selection!.addRange(originalRange)
    }

    // Get the focus back on the previously focused element, if any
    if (previouslyFocusedElement) {
      ;(previouslyFocusedElement as HTMLElement).focus()
    }
  }
}

export function arrayChunk<T>(source: T[] = [], chunk = 3): ChunkList<T>[] {
  if (isNaN(Number(chunk))) {
    return []
  }
  if (Object.prototype.toString.call(source) !== '[object Array]') {
    return []
  }
  const result = []
  for (let i = 0, j = source.length; i < j; i += chunk) {
    result.push(source.slice(i, i + chunk))
  }

  return result.map((item: any, index) => {
    return {
      id: item.id || index,
      children: item,
    }
  })
}

export class WindowManager {
  private static instance: WindowManager
  private childWindows: Map<string, Window>
  private messageHandlers: Map<string, (event: MessageEvent) => void>

  private constructor() {
    this.childWindows = new Map()
    this.messageHandlers = new Map()
  }

  static getInstance(): WindowManager {
    if (!this.instance) {
      this.instance = new WindowManager()
    }
    return this.instance
  }

  // 打开或获取已存在的窗口
  openWindow(url: string, name = 'hnswxxy'): Window | null {
    if (!process.client) return null

    try {
      // 检查是否已存在且未关闭
      const existingWindow = this.childWindows.get(name)
      if (existingWindow && !existingWindow.closed) {
        existingWindow.focus()
        existingWindow.location.href = url
        return existingWindow
      }

      // 打开新窗口
      const newWindow = window.open(url, name)
      if (!newWindow) {
        throw new Error('Failed to open window')
      }

      this.childWindows.set(name, newWindow)
      return newWindow
    } catch (error) {
      console.error('Failed to open window:', error)
      return null
    }
  }
  // 移除旧的消息处理器
  private removeMessageHandler(windowName: string) {
    const oldHandler = this.messageHandlers.get(windowName)
    if (oldHandler) {
      window.removeEventListener('message', oldHandler)
      this.messageHandlers.delete(windowName)
    }
  }
  // 发送消息到指定窗口
  sendMessage(baseUrl: string, data: any, windowName = 'hnswxxy') {
    if (!process.client) return

    const targetWindow = this.childWindows.get(windowName)
    if (!targetWindow || targetWindow.closed) {
      console.error('Target window not found or closed')
      return
    }
    this.removeMessageHandler(windowName)
    // 创建消息处理器
    const messageHandler = (event: MessageEvent) => {
      if (event.origin !== baseUrl) return

      if (event.data === 'ready') {
        targetWindow.postMessage(
          {
            type: 'USER_DATA',
            data: data,
          },
          baseUrl
        )
      }
      if (event.data === 'logout') {
        useLoginStore().logout()
      }
    }

    // 存储消息处理器以便后续清理
    this.messageHandlers.set(windowName, messageHandler)
    window.addEventListener('message', messageHandler)
  }

  sendMessageToLogout(baseUrl: string, data: any, windowName = 'hnswxxy') {
    const targetWindow = this.childWindows.get(windowName)
    if (!targetWindow || targetWindow.closed) {
      console.error('Target window not found or closed')
      return
    }
    targetWindow.postMessage(
      {
        type: 'USER_DATA',
        data: data,
      },
      baseUrl
    )
  }

  // 直接发送消息到opener
  sendToOpener(baseUrl: string, data: any) {
    if (!process.client || !window.opener) return

    window.opener.postMessage(
      {
        type: 'USER_DATA',
        data: data,
      },
      baseUrl
    )
  }

  // 清理指定窗口
  closeWindow(name: string) {
    const win = this.childWindows.get(name)
    if (win && !win.closed) {
      win.close()
    }
    this.childWindows.delete(name)

    // 清理对应的消息处理器
    const handler = this.messageHandlers.get(name)
    if (handler) {
      window.removeEventListener('message', handler)
      this.messageHandlers.delete(name)
    }
  }

  // 清理所有窗口
  closeAll() {
    this.childWindows.forEach((win, name) => {
      this.closeWindow(name)
    })
  }
}

// 导出工具函数
export const windowManager = WindowManager.getInstance()

// 封装消息发送函数
export const sendMessage = (baseUrl: string, url?: string, data?: any) => {
  if (!process.client) return

  if (url) {
    // 打开新窗口并发送消息
    const win = windowManager.openWindow(url)
    if (win && data) {
      windowManager.sendMessage(baseUrl, data)
    }
  } else if (window.opener) {
    // 直接发送消息到opener
    windowManager.sendToOpener(baseUrl, data)
  }
}

// //跨页面通信
// export const sendMessage = (baseUrl: string, url: string, data: any) => {
//   // 确保在客户端环境
//   if (!process.client) return

//   try {
//     // 打开新窗口
//     const win = window.open(url, 'hnswxxy')
//     if (!win) {
//       throw new Error('Failed to open window')
//     }

//     // 监听目标窗口的准备就绪消息
//     const messageHandler = (event: MessageEvent) => {
//       // 验证消息来源
//       if (event.origin !== baseUrl) return
//       if (event.data === 'ready') {
//         win.postMessage(
//           {
//             type: 'USER_DATA',
//             data: data,
//           },
//           baseUrl // 只需要域名部分
//         )
//       }
//       if (event.data === 'logout') {
//         useLoginStore().logout()
//       }
//     }

//     window.addEventListener('message', messageHandler)

//     onUnmounted(() => {
//       if (process.client) {
//         window.removeEventListener('message', messageHandler)
//       }
//     })
//   } catch (error) {
//     console.error('Failed to send data:', error)
//   }
// }
