export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"海南省物业管理行业协会"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"}],"link":[{"rel":"icon","href":"/logo.png","sizes":"any"},{"rel":"icon","href":"/logo.png"},{"rel":"apple-touch-icon","href":"/logo.png"}],"style":[],"script":[],"noscript":[],"viewport":"width=device-width,initial-scale=1"}

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnUrl = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = false

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'